<template>
  <div>
    <!-- <TheHeader />
    <TheBody /> -->
    <TheSellPage />
  </div>
</template>
<script>
// @ is an alias to /src
// import TheBody from "@/components/sell/TheSellpageBody.vue";
// import TheHeader from "@/components/sell/TheSellpageHeader.vue";

export default {
  name: "Sell",
  components: {
    // TheBody,
    // TheHeader
    TheSellPage: () => import("@/components/sell/TheSellPage.vue"),
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      let u = vm.$store.getters.user;

      vm.$store.dispatch("listingAgreement", false);
      if (vm.$store.getters.country.Code !== "CA") {
        vm.$bvModal.show("registerCountryNotFound");
        vm.$router.back();
      } else if (!u) {
        vm.$bvModal.show("signin");
        vm.$router.back();
      } else if(!vm.$store.getters.userType.includes('Seller')){
        if(!u.IsEmailVerified){
          vm.$store.dispatch('resendEmail');
        }else {
          vm.$bvModal.show("beSellerModal")
        }
        //vm.$router.back();
      } else if (!vm.$store.getters.verifyForListing && !vm.$store.getters.doneRegister) {
        vm.$bvModal.show("sellerChecking");
        vm.$router.back();
      }
    });
  }
};
</script>
